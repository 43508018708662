<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card>
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h4>{{ $t('active-sessions') }}</h4>
          <b-button
            :disabled="onDeleteAllLoading"
            variant="danger"
            @click="onDeleteAll"
          >
            <span v-if="!onDeleteAllLoading"> {{ $t('remove-all') }}</span>
            <b-spinner
              v-else
              small
              size="10"
              variant="light"
              type="grow"
            />
          </b-button>
        </div>
        <b-row>
          <b-col
            v-for="activeSession in activeSessions"
            :key="activeSession.id"
            sm="4"
          >
            <activeSessionsCard
              :active-session="activeSession"
              @delete="onDelete"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import activeSessionsCard from '@/views/components/activeSessionsCard.vue'
import ProfileApis from '@/services/apis/profile'

const UAParser = require('ua-parser-js')

const profile = new ProfileApis()
export default {
  components: {
    activeSessionsCard,
  },
  data() {
    return {
      activeSessions: [],
      loading: false,
      onDeleteAllLoading: false,
    }
  },
  mounted() {
    this.getSessions()
  },
  methods: {
    getSessions() {
      this.loading = true
      profile.activeSessions().then(async res => {
        this.activeSessions = res.data.results.map(ac => ({ ...ac, agent: this.getUserAgentDetails(ac.user_agent) }))
      }).finally(() => {
        this.loading = false
      })
    },
    getUserAgentDetails(uaString) {
      const parser = new UAParser(uaString)
      return {
        browser: parser.getBrowser(),
        device: parser.getDevice(),
        os: parser.getOS(),
        engine: parser.getEngine(),
        cpu: parser.getCPU(),
      }
    },
    onDelete() {
      this.getSessions()
    },
    onDeleteAll() {
      this.onDeleteAllLoading = true
      profile.deleteActiveSessions({}).then(() => {
        this.getSessions()
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          this.onDeleteAllLoading = false
        })
    },
  },
}
</script>
