<template>
  <div
    v-if="activeSession.agent"
    class="w-100"
  >
    <b-card style="border: 1px solid gray;">
      <div class="d-flex justify-content-between">
        <h5>{{ $t('IP-address') }}: {{ activeSession.ip_address }}</h5>
        <h5>{{ $t('date') }}: {{ moment(activeSession.created_at) }}</h5>
      </div>
      <hr>
      <div class="d-flex justify-content-between">
        <div>
          <h5 v-if="activeSession.ip_info">
            {{ $t('country') }}: {{ activeSession.ip_info.country }} ({{ activeSession.ip_info.city }})
          </h5>
          <h5>{{ $t('browser') }}:  {{ activeSession.agent.browser.name }}</h5>
          <h5>{{ $t('OS') }}:  {{ activeSession.agent.os.name }} ({{ activeSession.agent.os.version }})</h5>
        </div>
        <div>
          <b-button
            variant="warning"
            :disabled="onDeleteLoading"
            @click="onDelete()"
          >
            <span v-if="!onDeleteLoading"> {{ $t('remove') }}</span>
            <b-spinner
              v-else
              small
              size="10"
              variant="light"
              type="grow"
            />
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment-jalaali'
import ProfileApis from '@/services/apis/profile'

const profile = new ProfileApis()

export default {
  props: {
    activeSession: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      onDeleteLoading: false,
      moment,
    }
  },
  mounted() {

  },
  methods: {
    onDelete() {
      this.onDeleteLoading = true
      profile.deleteActiveSessions({ session_id: this.activeSession.id }).then(() => {
        this.$emit('delete', this.activeSession.id)
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          this.onDeleteLoading = false
        })
    },
  },
}
</script>
