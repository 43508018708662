<template>
  <div>
    <b-card :title="$t('security-password')">
      <b-alert
        show
        variant="success"
        class="p-2"
      >
        <h5>
          {{ $t('security-password-alert') }}
        </h5>
      </b-alert>
      <validation-observer ref="form">
        <b-form>
          <b-row class="mt-2">
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-3"
            >
              <b-form-group
                label-for="account-new-password"
                :label="$t('new_Password')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('new_Password')"
                  vid="new_password"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-new-password"
                      v-model="form.password"
                      :type="passwordFieldTypeNew"
                      name="new-password"
                      :placeholder="$t('new_Password')"
                      minlength="8"
                      maxlength="16"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-3"
            >
              <b-form-group
                label-for="account-retype-new-password"
                :label="$t('retype_new_password')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('retype_new_password')"
                  vid="retype_password"
                  rules="required|confirmed:new_password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="form.password_confirmation"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      :placeholder="$t('retype_new_password')"
                      minlength="8"
                      maxlength="16"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-3"
            >
              <b-form-group
                label-for="otp"
                :label="$t('otp')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('otp')"
                  vid="otp"
                  rules="required"
                >
                  <b-form-input
                    id="otp"
                    v-model="form.otp_code"
                    name="otp"
                    :placeholder="$t('otp')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-3"
            >
              <b-button
                class="mt-2"
                variant="success"
                :disabled="otpLoading || otpResendTimer > 0"
                @click="sendOtp"
              >
                <span v-if="otpLoading">
                  <b-spinner
                    small
                    type="grow"
                  />
                </span>
                <span v-else-if="otpResendTimer > 0">
                  {{ convertSecondsToMinutes(otpResendTimer) }}
                </span>
                <span v-else>
                  {{ $t('send-otp') }}
                </span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <b-button
                v-if="!pending"
                variant="primary"
                class="mt-1 mr-1"
                @click="save"
              >
                {{ $t('save_changes') }}
              </b-button>
              <b-button
                v-if="pending"
                variant="primary"
                class="mt-1"
                disabled
              >
                <b-spinner
                  small
                  type="grow"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import ProfileApis from '@/services/apis/profile'
import { convertSecondsToMinutes } from '@/utils'

const profile = new ProfileApis()
export default {
  data() {
    return {
      convertSecondsToMinutes,
      otpLoading: false,
      otpResendTimer: 0,
      pending: false,
      form: {
        password: '',
        password_confirmation: '',
        otp_code: '',
      },
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      pendingTwoFactor: false,
      pendingReSendOtp: false,
      pendingOTP: false,
      reSendBtn: false,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
  },
  methods: {
    async sendOtp() {
      this.otpLoading = true
      await profile.sendOtp().then(res => {
        this.otpResendTimer = res.data.results.remained_time
        this.countDownResetOtp()
      }).catch((err => {
        this.otpResendTimer = err.data.results.remained_time
        this.countDownResetOtp()
      })).finally(() => {
        this.otpLoading = false
      })
    },
    async save() {
      const success = await this.$refs.form.validate()
      if (success) {
        this.pending = true
        await profile.tradingPassword(this.form).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          this.form = {
            password: '',
            password_confirmation: '',
            otp_code: '',
          }
          this.$refs.form.reset()
        }).finally(() => {
          this.pending = false
          this.otpResendTimer = 0
        })
      }
    },
    countDownResetOtp() {
      setTimeout(() => {
        this.otpResendTimer -= 1
        if (this.otpResendTimer > 0) {
          this.countDownResetOtp()
        }
      }, 1000)
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
